<template>
  <section class="page-body">
    <TitleBar :title="$t('my_profile')" />
    <v-container class="mt-1">
      <loading
        :active="loader"
        :is-full-page="fullPage"
        :opacity="0.9"
        color="#ff6b00"
        :height="height"
        :width="width"
      />
      <v-alert
        v-if="alertMsg.status"
        :timeout="alertMsg.timeout"
        :color="alertMsg.color"
        :type="alertMsg.type"
        dismissible
      >
        {{ alertMsg.text }}
      </v-alert>
      <v-row>
        <v-col cols="12" md="12" class="mb-3">
          <v-expansion-panels
            elevation-1
            class="rounded-lg"
            rounded="lg"
            v-model="panelActive"
          >
            <v-expansion-panel>
              <v-expansion-panel-header
                class="font-weight-bold text-overline"
                >{{ $t("profile_information") }}</v-expansion-panel-header
              >
              <v-expansion-panel-content class="mt-4">
                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                    sm="12"
                    class="text-center d-flex justify-center"
                  >
                    <v-card flat>
                      <v-img
                        :src="profileLink"
                        style="
                          width: 100%;
                          height: 150px;
                          max-height: 150px;
                          max-width: 150px;
                          border: 1px solid #f1f1f1;
                        "
                        class="rounded-circle white--text align-end mx-auto-xs"
                        :alt="$t('my_profile')"
                        v-if="profileLink"
                      />
                      <v-img
                        src="../../assets/user-profile-icon-free-vector.webp"
                        :alt="$t('my_profile')"
                        style="width: 100%"
                        class="rounded-circle white--text align-end mx-auto-xs"
                        v-else
                      />

                      <v-card-actions>
                        <v-btn
                          color="orange"
                          text
                          outlined
                          small
                          class="text-capitalize"
                          @click="triggerFileInput"
                        >
                          <v-icon>mdi-pencil</v-icon> {{ $t("update_profile") }}
                        </v-btn>

                        <v-file-input
                          v-model="profile_pic"
                          :placeholder="$t('update_profile')"
                          dense
                          accept="image/png, image/jpeg, image/bmp"
                          :show-size="1000"
                          prepend-icon=""
                          :title="$t('update_profile_placeholder')"
                          solo
                          truncate-length="15"
                          @change="onFileChange"
                          class="mt-2 d-none"
                          id="profile_image"
                        >
                        </v-file-input>
                      </v-card-actions>
                    </v-card>
                  </v-col>

                  <v-col cols="12" md="9" sm="12">
                    <h3 class="text-h5 mb-1" v-if="profileData.customer_name">
                      {{ profileData.customer_name }}
                    </h3>
                    <p class="subtitle-2" v-if="profileData.phone_number">
                      <strong>{{ $t("phone_no") }}: </strong>
                      {{ profileData.phone_number
                      }}<v-icon
                        :color="
                          profileData.phone_number
                            ? 'green darken-2'
                            : 'grey darken-2'
                        "
                        small
                        >mdi-checkbox-marked-circle</v-icon
                      >
                    </p>
                    <p class="subtitle-2" v-if="user_email">
                      <strong>{{ $t("e_mail") }}: </strong>
                      {{ profileData.email_id }}
                      <v-icon
                        :color="
                          profileData.is_email_confirmation
                            ? 'green darken-2'
                            : 'grey darken-2'
                        "
                        small
                        >mdi-checkbox-marked-circle</v-icon
                      >
                    </p>
                    <p class="subtitle-2">
                      <strong>{{ $t("Pay ID") }}: </strong>
                      <v-chip
                        label
                        class="mt-1 chip-styles"
                        color="grey lighten-2"
                      >
                        <p class="mb-0">{{ profileData.pay_id }}</p>
                      </v-chip>
                      <v-btn
                        x-small
                        fab
                        icon
                        @click="copypayIdfromGrid(profileData.pay_id)"
                      >
                        <v-icon small>mdi-content-copy</v-icon>
                      </v-btn>
                    </p>
                    <div>
                      <v-btn small class="ma-2 text-capitalize" text outlined>
                        {{ $t("complete_my_profile") }}
                        <v-icon
                          right
                          dark
                          :color="
                            is_profile_completed
                              ? 'green darken-2'
                              : 'grey darken-2'
                          "
                          >mdi-checkbox-marked-circle</v-icon
                        >
                      </v-btn>

                      <v-btn small class="ma-2 text-capitalize" text outlined>
                        {{ $t("2fa_security") }}
                        <v-icon
                          right
                          dark
                          :color="
                            is_2FA_Enabled ? 'green darken-2' : 'grey darken-2'
                          "
                          >mdi-checkbox-marked-circle</v-icon
                        >
                      </v-btn>

                      <v-btn small class="ma-2 text-capitalize" text outlined>
                        {{ $t("complete_kyc") }}
                        <v-icon
                          right
                          dark
                          :color="
                            is_kyc_completed === 'Approved'
                              ? 'green darken-2'
                              : 'grey darken-2'
                          "
                          >mdi-checkbox-marked-circle</v-icon
                        >
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="User_Type === 'IND'">
              <v-expansion-panel-header
                class="font-weight-bold text-overline"
                >{{ $t("personal_information") }}</v-expansion-panel-header
              >
              <v-expansion-panel-content class="py-4">
                <INDprofile v-if="User_Type === 'IND'" />
                <!-- <BUSprofile v-if="User_Type === 'BUS'" /> -->
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="font-weight-bold text-overline"
                >{{ $t("language_settings") }}</v-expansion-panel-header
              >
              <v-expansion-panel-content class="py-4">
                <v-row>
                  <v-col cols="12" md="3">
                    <label>{{ $t("select_language") }}</label>
                    <v-autocomplete
                      v-model="language_selected"
                      :placeholder="$t('select_language')"
                      :items="language_list"
                      outlined
                      dense
                    ></v-autocomplete>

                    <v-btn
                      block
                      color="primary"
                      class="text-capitalize"
                      @click="updateLanguage"
                      >{{ $t("save") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="font-weight-bold text-overline"
                >{{ $t("email_verification") }}</v-expansion-panel-header
              >
              <p class="px-5">
                {{ $t("email_verification_subtext")
                }}<v-icon
                  :color="
                    profileData.is_email_confirmation
                      ? 'green darken-2'
                      : 'grey darken-2'
                  "
                  small
                  >mdi-checkbox-marked-circle</v-icon
                >
              </p>
              <v-expansion-panel-content class="py-4">
                <emailVerificationVue
                  @getProfile="getProfile"
                  :profileData="profileData"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="!is_partner">
              <v-expansion-panel-header
                class="font-weight-bold text-overline"
                >{{ $t("change_password") }}</v-expansion-panel-header
              >
              <p class="px-5">********</p>
              <v-expansion-panel-content class="py-4">
                <v-form v-model="valid" ref="updatepassword">
                  <v-row>
                    <v-col cols="12" md="3">
                      <!-- <label>Enter New Password</label> -->
                      <v-text-field
                        v-model="oldPassword"
                        :append-icon="
                          isPwdVisible
                            ? 'mdi-eye-outline'
                            : 'mdi-eye-off-outline'
                        "
                        @click:append="() => (isPwdVisible = !isPwdVisible)"
                        :type="isPwdVisible ? 'text' : 'password'"
                        :label="$t('old_password')"
                        :placeholder="$t('enter_old_password')"
                        clearable
                        :error-messages="oldPasswordErrors"
                        :rules="oldPasswordrules"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <!-- <label>Enter Confirm Password</label> -->
                      <v-text-field
                        v-model="newPassword"
                        :append-icon="
                          isnewPwdVisible
                            ? 'mdi-eye-outline'
                            : 'mdi-eye-off-outline'
                        "
                        @click:append="
                          () => (isnewPwdVisible = !isnewPwdVisible)
                        "
                        :type="isnewPwdVisible ? 'text' : 'password'"
                        :label="$t('new_password')"
                        :placeholder="$t('enter_new_password')"
                        clearable
                        :error-messages="newPasswordErrors"
                        @input="passwordcheck(newPassword)"
                        :rules="newpasswordRules"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <!-- <label>Enter Confirm Password</label> -->
                      <v-text-field
                        v-model="cPassword"
                        :append-icon="
                          isCPwdVisible
                            ? 'mdi-eye-outline'
                            : 'mdi-eye-off-outline'
                        "
                        @click:append="() => (isCPwdVisible = !isCPwdVisible)"
                        :type="isCPwdVisible ? 'text' : 'password'"
                        :label="$t('confirm_password')"
                        :placeholder="$t('enter_confirm_password')"
                        clearable
                        :error-messages="cPasswordErrors"
                        :rules="confirmpasswordRules"
                        @input="ConfirmPassword(cPassword)"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="3">
                      <v-btn
                        block
                        color="primary"
                        class="text-capitalize"
                        @click="updatePassword"
                        >{{ $t("update") }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="!is_partner">
              <v-expansion-panel-header class="font-weight-bold">{{
                $t("2fa_authentication")
              }}</v-expansion-panel-header>
              <p class="px-5">
                {{ $t("status") }}:
                {{ is_2FA_Enabled ? "Enabled" : "Disabled" }}
              </p>
              <v-expansion-panel-content class="py-4">
                <!-- <v-row>
                  <v-col cols="12" md="6">
                    We’ll send you a security code by SMS to:
                  </v-col>
                </v-row> -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import TitleBar from "@/components/navigations/TitleBar.vue";
import INDprofile from "../../components/UserProfiles/INDprofile.vue";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import handleImageUpload from "../../mixins/compressImage.mixin";
import { i18n as $ } from "../../locale/index";
import userservice from "@/services/UserService/userservice";
import alerts from "@/mixins/alerts";
import emailVerificationVue from "./emailVerification.vue";
export default {
  name: "Profile",
  components: {
    TitleBar,
    emailVerificationVue,
    INDprofile,
  },
  mixins: [handleImageUpload, alerts],
  data: () => ({
    panelActive: 0,
    errorMessage: "",
    is_partner: false,
    showDismissibleAlert: false,
    User_Type: "",
    profileDetails: {
      title: "",
      user_type: "",
      first_name: "",
      last_name: "",
      middle_name: "",
      nationality: "",
      mobile_number: "",
      dob: "",
      gender: "",
      fund_source: "",
      occupation: "",
      employer: "",
      income_range: "",
      address: "",
      country_of_birth: "",
      postal_code: "",
      district: "",
      country: "",
      country_id: 0,
      country_code: "",
      state: "",
      state_id: 0,
      emailConfirm: "",
      state_code: "",
      city: "",
      city_id: 0,
      city_code: "",
    },
    profileLink: "",
    loader: false,
    fullPage: true,
    height: 100,
    width: 100,
    alertMsg: {
      status: false,
      text: "",
      timeout: 2000,
      color: "blue-grey",
      type: "info",
    },
    language_list: [
      { text: "English", value: "en-us" },
      { text: "Chinese", value: "zh-cn" },
      // { text: "Thai", value: "ta-in" },
    ],
    language_selected: "",
    user_email: "",
    is_2FA_Enabled: false,
    is_profile_completed: false,
    is_kyc_completed: "",
    profileData: {},
    profile_pic: null,
    oldPassword: "",
    newPassword: "",
    cPassword: "",
    isPwdVisible: false,
    isCPwdVisible: false,
    isnewPwdVisible: false,
    oldPasswordrules: [(value) => !!value || $.t("old_pass_req")],
    confirmpasswordRules: [(value) => !!value || $.t("conf_Password_req")],
    newpasswordRules: [(value) => !!value || $.t("password_field_is_required")],
  }),

  validations() {
    return {
      oldPassword: {
        required,
        minLength: minLength(6),
        validPassowrd: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        },
      },
      newPassword: {
        required,
        minLength: minLength(6),
        validPassowrd: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        },
      },
      cPassword: {
        required,
        minLength: minLength(6),
        sameAsPassword: sameAs("newPassword"),
        validPassowrd: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial
          );
        },
      },
    };
  },
  computed: {
    oldPasswordErrors() {
      const errors = [];
      if (!this.$v.oldPassword.$dirty) return errors;
      !this.$v.oldPassword.required &&
        errors.push(this.$t("password_is_required"));
      // !this.$v.oldPassword.minLength && errors.push(this.$t("password_length"));
      // !this.$v.oldPassword.validPassowrd &&
      //   errors.push(this.$t("password_validation_case"));
      return errors;
    },
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.newPassword.$dirty) return errors;
      !this.$v.newPassword.required &&
        errors.push(this.$t("password_is_required"));
      if (this.newPassword === this.oldPassword) {
        errors.push(this.$t("new_pass_notsameas"));
      }
      !this.$v.newPassword.minLength && errors.push(this.$t("password_length"));
      !this.$v.newPassword.validPassowrd &&
        errors.push(this.$t("password_validation_case"));
      return errors;
    },
    cPasswordErrors() {
      const errors = [];
      if (!this.$v.cPassword.$dirty) return errors;
      !this.$v.cPassword.required &&
        errors.push(this.$t("password_is_required"));
      !this.$v.cPassword.minLength && errors.push(this.$t("password_length"));
      !this.$v.cPassword.sameAsPassword &&
        errors.push(this.$t("password_same"));
      !this.$v.cPassword.validPassowrd &&
        errors.push(this.$t("password_validation_case"));
      return errors;
    },
    ...mapGetters("Authentication", ["user_profile"]),
  },
  mounted() {
    let partner_user = this.$getLocalStorage("is_partner");
    if (partner_user) {
      this.is_partner = true;
    } else {
      this.is_partner = false;
    }
    this.getProfile();
    this.verified_email = sessionStorage.getItem("user_email");
    console.log(this.verified_email);
    this.language_selected = sessionStorage.getItem("App_Language") || "en-us";
  },

  methods: {
    copypayIdfromGrid(payid) {
      navigator.clipboard.writeText(payid);
      this.showAlertSuccess("copied successfully.");
    },
    verifyPassword(newPassword) {
      if (newPassword === this.oldPassword) {
        this.newpasswordRules.push(this.$t("new_pass_notsameas"));
      } else {
        this.newpasswordRules = [(value) => !!value || "Password is Required."];
      }
    },
    ConfirmPassword(val) {
      if (this.newPassword != val) {
        this.confirmpasswordRules.push(this.$t("Pass_is_mismatch"));
      } else {
        this.confirmpasswordRules = [
          (value) => !!value || this.$t("password_is_required"),
        ];
      }
    },
    async passwordcheck(val) {
      const data = {
        password: val,
      };
      try {
        // const responseData = await axios.post(
        //   `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/validate-password`,
        //   data
        // );
        const responseData = await userservice.validatePassword(data);
        console.log("responseData", responseData.data);
        if (responseData.data.status_code == 200) {
          this.newpasswordRules = [
            (value) => !!value || this.$t("password_is_required"),
          ];
          this.verifyPassword(val);
        } else {
          this.newpasswordRules = [];
          this.newpasswordRules.push(responseData.data.message);
        }
      } catch (e) {
        this.loader = false;
        return e;
      }
    },
    enterKeyPressed(event) {
      if (event.keyCode == 13) this.onSubmitVerify();
    },

    getProfile() {
      this.loader = true;
      this.$store
        .dispatch("Authentication/getProfile")
        .then((res) => {
          console.log("getProfile ", res.data.data);
          this.$emit("getProfile");
          this.loader = false;
          if (!res) return;
          const responseArray = res.data.data;
          console.log("responseArray", responseArray);
          if (Array.isArray(responseArray) && responseArray.length === 0)
            return;
          this.profileData = responseArray[0];
          this.profileLink = responseArray[0].profile_pic
            ? `${responseArray[0].profile_pic}`
            : "";
          this.profileDetails.fName = responseArray[0].customer_name;
          sessionStorage.setItem("user_fname", responseArray[0].first_name);
          sessionStorage.setItem("user_lname", responseArray[0].last_name);
          sessionStorage.setItem(
            "complete_registration",
            responseArray[0].registration_status
          );
          this.$store.commit(
            "complete_registration",
            responseArray[0].registration_status
          );

          this.User_Type = sessionStorage.getItem("user_type");
          this.user_email = responseArray[0].email_id;
          this.is_2FA_Enabled = responseArray[0].two_factor_enabled;
          this.is_profile_completed = responseArray[0].user_status;
          this.is_kyc_completed = responseArray[0].kyc_status;
          this.emailId = responseArray[0].email_id
            ? responseArray[0].email_id
            : "";
          sessionStorage.setItem(
            "is_email_confirm",
            responseArray[0].is_email_confirmation
          );
          console.log(" responseArray.dob", responseArray[0]);
        })
        .catch((err) => {
          console.error(err);
          this.loader = false;
        });
    },
    async onFileChange() {
      if (!this.profile_pic) return;
      this.loader = true;
      let compressdata = await this.handleImageUpload(this.profile_pic);
      console.log(compressdata);
      let fileData = new FormData();
      fileData.append("profile_pic", compressdata);
      try {
        const responseData = await userservice.uploadProfileImage(fileData);
        if (responseData.data.status_code == 200) {
          this.loader = false;
          this.getProfile();
          this.showAlertSuccess("User profile updated successfuly.");
        } else {
          this.loader = false;
          this.showAlertError(
            `Profile information not added:${responseData.data}`
          );
        }
      } catch (err) {
        console.error(err);
        this.showAlertError(`Something went wrong please try again later.`);
        this.loader = false;
      }
      // try {
      //   this.loader = true;
      //   const responseData = await this.$store.dispatch(
      //     "Authentication/uploadProfileImage", //flag for upload
      //     fileData
      //   );

      //   console.log(`uploadProfileImage: `, responseData);
      //   if (responseData.data.status_code == 200) {
      //     this.getProfile();
      //     this.loader = false;
      //     this.alertMsg.text = "User profile updated successfuly.";
      //     this.alertMsg.status = true;
      //     this.alertMsg.color = "green";
      //     this.alertMsg.type = "success";
      //   } else {
      //     this.loader = false;
      //     this.alertMsg.text = `Profile information not added:${responseData.data}`;
      //     this.alertMsg.status = true;
      //     this.alertMsg.color = "warning";
      //     this.alertMsg.type = "error";
      //   }
      // } catch (error) {
      //   this.alertMsg.text = `Error:Something went wrong please try again later.`;
      //   this.alertMsg.status = true;
      //   this.alertMsg.color = "warning";
      //   this.alertMsg.type = "error";
      //   this.loader = false;
      // }
    },
    async updateLanguage() {
      console.log("update language: ", this.language_selected);
      this.loader = true;
      sessionStorage.setItem("App_Language", this.language_selected);
      this.$i18n.locale = this.language_selected;
      try {
        const responseData = await userservice.updateLanguage({
          language: this.language_selected,
        });
        if (responseData.data.status_code == 200) {
          this.loader = false;
          this.showAlertSuccess(responseData.data.message);
        } else {
          this.loader = false;
          this.showAlertError(responseData.data.message);
        }
      } catch (err) {
        console.error(err);
        this.loader = false;
      }
      // const token = await sessionStorage.getItem("access_token");
      // await axios({
      //   url: `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/change-language?language`,
      //   method: "GET",
      // params: {
      //   language: this.language_selected,
      // },
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${token}`,
      //     accept: "application/json",
      //   },
      // })
      //   .then((res) => {
      //     this.loader = false;

      //     this.alertMsg.status = true;
      //     this.alertMsg.text = res.data.message;
      //     this.alertMsg.type = "success";
      //     this.alertMsg.color = "green";
      //   })
      //   .catch((err) => {
      //     this.loader = false;

      //     this.alertMsg.status = true;
      //     this.alertMsg.text = err.message;
      //     this.alertMsg.type = "error";
      //     this.alertMsg.color = "warning";
      //     console.error(err);
      //   });
    },
    triggerFileInput() {
      document.getElementById("profile_image").click();
    },
    async updatePassword() {
      if (this.$refs.updatepassword.validate()) {
        try {
          this.loader = true;
          // const token = await sessionStorage.getItem("access_token");
          // const responseData = await axios.put(
          //   `${process.env.VUE_APP_USER_SERVICE}/api/v1/user/update-password?old_password=${this.oldPassword}&password=${this.newPassword}`,
          //   {},
          //   {
          //     headers: {
          //       "Content-Type": "application/json",
          //       Authorization: `Bearer ${token}`,
          //       accept: "application/json",
          //     },
          //   }
          // );
          const responseData = await userservice.updateOldpwtoNewpwd(
            this.oldPassword,
            this.newPassword
          );
          console.log("responseData", responseData);
          if (responseData && responseData.data.status_code === 200) {
            this.loader = false;
            // this.alertMsg.status = true;
            // this.alertMsg.text = responseData.data.message;
            // this.alertMsg.type = "success";
            // this.alertMsg.color = "green";
            this.showAlertSuccess(responseData.data.message);
            setTimeout(() => {
              this.$router.push({
                name: "Login",
                params: { emailId: sessionStorage.getItem("user_email") },
              });
            }, 1500);
          } else {
            this.loader = false;
            // this.alertMsg.status = true;
            // this.alertMsg.text = responseData.data.message;
            // this.alertMsg.type = "error";
            // this.alertMsg.color = "warning";
            this.showAlertError(responseData.data.message);
          }
        } catch (error) {
          this.loader = false;
          // this.alertMsg.status = true;
          // this.alertMsg.text = "Password cannot be same or already used!";
          // this.alertMsg.type = "error";
          // this.alertMsg.color = "warning";
          this.showAlertError("Password cannot be same or already used!");
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/global.scss";

::v-deep .btn-light {
  border: 1px solid #d3d3d3 !important;
}

.pro-pic {
  margin-top: -69px !important;
  margin-bottom: -106px !important;
}

.container {
  max-width: 1100px;
  padding: 0 20px;
  margin: 0 auto;
}

.panel {
  margin: 100px auto 40px;
  max-width: 500px;
  text-align: center;
}

.button_outer {
  background: #83ccd3;
  border-radius: 30px;
  text-align: center;
  height: 50px;
  width: 200px;
  display: inline-block;
  transition: 0.2s;
  position: relative;
  overflow: hidden;
}

.btn_upload {
  padding: 17px 30px 12px;
  color: #fff;
  text-align: center;
  position: relative;
  display: inline-block;
  overflow: hidden;
  z-index: 3;
  white-space: nowrap;
}

.btn_upload input {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 105%;
  cursor: pointer;
  opacity: 0;
}

.file_uploading {
  width: 100%;
  height: 10px;
  margin-top: 20px;
  background: #ccc;
}

.file_uploading .btn_upload {
  display: none;
}

.processing_bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  border-radius: 30px;
  background: #ff6b00;
  transition: 3s;
}

.file_uploading .processing_bar {
  width: 100%;
}

.success_box {
  display: none;
  width: 50px;
  height: 50px;
  position: relative;
}

.success_box:before {
  content: "";
  display: block;
  width: 9px;
  height: 18px;
  border-bottom: 6px solid #fff;
  border-right: 6px solid #fff;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  left: 17px;
  top: 10px;
}

.file_uploaded .success_box {
  display: inline-block;
}

.file_uploaded {
  margin-top: 0;
  width: 50px;
  background: #83ccd3;
  height: 50px;
}

.uploaded_file_view {
  max-width: 300px;
  margin: 40px auto;
  text-align: center;
  position: relative;
  transition: 0.2s;
  opacity: 0;
  border: 2px solid #ddd;
  padding: 15px;
}

.file_remove {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  position: absolute;
  background: #aaa;
  line-height: 30px;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
  right: -15px;
  top: -15px;
}

.file_remove:hover {
  background: #222;
  transition: 0.2s;
}

.uploaded_file_view img {
  max-width: 100%;
}

.uploaded_file_view.show {
  opacity: 1;
}

.error_msg {
  text-align: center;
  color: #f00;
}
</style>
