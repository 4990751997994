<template>
  <v-row>
    <v-col v-if="profileData.is_email_confirmation">
      <p>
        {{ $t("email_is_verified") }}
        <span class="primary--text">{{ profileData.email_id }}</span
        ><v-icon
          :color="
            profileData.is_email_confirmation
              ? 'green darken-2'
              : 'grey darken-2'
          "
          small
          >mdi-checkbox-marked-circle</v-icon
        >
      </p>
    </v-col>
    <v-col cols="12" sm="12" lg="6" class="pb-2" v-else>
      <div class="w-100 m-auto p-5 main-text">
        <br />

        <label class="text-midgray-color">{{ $t("confirm_email_id") }}</label>
        <v-text-field
          :label="$t('emailID')"
          required
          clearable
          v-model="emailId"
          @input="$v.emailId.$touch()"
          @blur="$v.emailId.$touch()"
          :error-messages="emailErrors"
          :disabled="profileData.email_id ? true : false"
          @keydown="enterKeyPressed"
        ></v-text-field>
        <div class="m-auto position-relative" style="max-width: 300px">
          <v-otp-input
            length="6"
            type="number"
            :disabled="isOTPLoading"
            class="font-weight-bold"
            v-model="otpEntered"
            @finish="onOTPUpdate"
            v-if="isOTPEnable"
          ></v-otp-input>

          <h6 class="text-dark" v-if="timerCount > 0 && isOTPEnable">
            {{ $t("otp will expire in the next") }}
            <span class="font-weight-bold">{{ timerCount }}</span>
            {{ $t("seconds") }}
          </h6>
          <div
            class="text-center d-flex justify-content-center align-items-baseline"
            v-if="timerCount === 0 && isOTPEnable"
          >
            <span class="text-dark">{{ $t("did_not_receive_the_otp") }}?</span>
            <v-btn
              class="text-capitalize"
              color="primary"
              @click="getOTPCode"
              text
              >{{ $t("resend_otp") }}</v-btn
            >
          </div>
        </div>

        <v-overlay absolute :value="isOTPLoading">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-overlay>

        <v-btn
          block
          rounded
          color="primary"
          class="btn-primary mt-3 text-capitalize"
          @click="onSubmitVerify"
          :disabled="isOTPEnable"
          v-if="!isOTPEnable"
        >
          <div class="button-loader" v-if="loader">
            <span class="spinner-border spinner-border-sm"></span>
          </div>
          <div v-else>{{ $t("verify") }}</div>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import userservice from "@/services/UserService/userservice";
import { i18n as $ } from "../../locale/index";
import alerts from "@/mixins/alerts";
export default {
  props: ["profileData"],
  mixins: [alerts],
  data: () => ({
    emailId: "",
    verified_email: "",
    otpEntered: 0,
    isOTPLoading: false,
    isOTPEnable: false,
    timerCount: 0,
    loader: false,
    dismissSecs: 5,
    dismissCountDown: 0,
  }),
  validations() {
    return {
      emailId: {
        required,
        email,
      },
    };
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0 && this.isOTPEnable) {
          setTimeout(() => {
            if (this.timerCount === 0) return;
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.emailId.$dirty) return errors;
      !this.$v.emailId.required && errors.push($.t("email_errors"));
      !this.$v.emailId.email && errors.push($.t("invalid_email"));
      return errors;
    },
  },
  methods: {
    async getOTPCode() {
      const user_email = sessionStorage.getItem("user_email") || this.emailId;
      console.log(`user_email: ${user_email}`);
      try {
        const responseData = await userservice.getOTPbyMail(user_email);
        if (responseData && responseData.data.status_code == 200) {
          this.timerCount = 5 * 60;

          this.showAlertSuccess(responseData.data.message);
        } else {
          this.showAlertError(responseData.data.message);
        }
        return responseData;
      } catch (err) {
        this.showAlertError(`Something went wrong please try again later.`);
        return err;
      }
    },
    async onSubmitVerify() {
      try {
        this.loader = true;
        const responseData = await userservice.getVerifyMail(this.emailId);

        console.log(responseData);
        if (responseData && responseData.data.status_code == 200) {
          this.loader = false;

          this.showAlertSuccess(responseData.data.message);
          sessionStorage.setItem("user_email", this.emailId);
          this.$store.commit("user_email", this.emailId);
          this.isOTPEnable = true;
          this.timerCount = 5 * 60;
        } else {
          this.loader = false;
          this.isOTPEnable = false;

          this.showAlertError(responseData.data.message);
        }
      } catch (error) {
        this.loader = false;
        this.isOTPEnable = false;

        this.showAlertError(`Something went wrong please try again later.`);
      }
    },
    async onOTPUpdate(otpEntered) {
      this.isOTPLoading = true;
      try {
        const res = await userservice.confirmOTPmail({
          user_email: this.emailId,
          otp: otpEntered,
        });
        if (res && res.data.success) {
          this.showAlertSuccess(res.data.message);
          this.isOTPLoading = false;
          this.isOTPEnable = false;
          this.verified_email = this.emailId;
          this.$emit("getProfile");
        } else {
          this.isOTPLoading = false;
          this.isOTPEnable = true;
          this.showAlertError(res.data.message);
        }
      } catch (err) {
        console.error(`confirm-otp error: ${err}`);

        this.isOTPLoading = false;
        this.isOTPEnable = true;
        this.showAlertError(`Something went wrong please try again later.`);
      }
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
  },
};
</script>

<style></style>
