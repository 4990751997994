<template>
  <v-container>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td class="text-left">{{ $t("name") }}</td>
            <td class="text-left">
              {{
                IndividualUserData.customer_name
                  ? IndividualUserData.customer_name
                  : ""
              }}
            </td>
          </tr>

          <tr>
            <td class="text-left">{{ $t("date_of_birth") }}</td>
            <td class="text-left">
              {{ IndividualUserData.dob ? IndividualUserData.dob : "" }}
            </td>
          </tr>

          <tr>
            <td class="text-left">{{ $t("nationality_label") }}</td>
            <td class="text-left">
              {{
                IndividualUserData.nationality_code
                  ? IndividualUserData.nationality_code
                  : ""
              }}
            </td>
          </tr>

          <tr>
            <td class="text-left">{{ $t("gender_label") }}</td>
            <td class="text-left">
              {{ IndividualUserData.gender }}
            </td>
          </tr>

          <tr>
            <td class="text-left">{{ $t("country_of_birth") }}</td>
            <td class="text-left">
              {{
                IndividualUserData.country_of_birth
                  ? IndividualUserData.country_of_birth
                  : ""
              }}
            </td>
          </tr>

          <tr>
            <td class="text-left">{{ $t("address_label") }}</td>
            <td class="text-left">
              {{ IndividualUserData.address ? IndividualUserData.address : "" }}
            </td>
          </tr>
          <tr>
            <td class="text-left">{{ $t("country_label") }}</td>
            <td class="text-left">
              {{ IndividualUserData.country ? IndividualUserData.country : "" }}
            </td>
          </tr>
          <tr>
            <td class="text-left">{{ $t("state_label") }}</td>
            <td class="text-left">
              {{
                IndividualUserData.state ? IndividualUserData.state.name : ""
              }}
            </td>
          </tr>
          <tr>
            <td class="text-left">{{ $t("city_lable") }}</td>
            <td class="text-left">
              {{ IndividualUserData.city ? IndividualUserData.city.name : "" }}
            </td>
          </tr>

          <tr>
            <td class="text-left">{{ $t("postal_code_label") }}</td>
            <td class="text-left">
              {{
                IndividualUserData.zip_code ? IndividualUserData.zip_code : ""
              }}
            </td>
          </tr>

          <tr>
            <td class="text-left">{{ $t("occupation") }}</td>
            <td class="text-left">
              {{
                IndividualUserData.occupation_code
                  ? IndividualUserData.occupation_code.value
                  : ""
              }}
            </td>
          </tr>

          <tr>
            <td class="text-left">{{ $t("source_of_income_label") }}</td>
            <td class="text-left">
              {{
                IndividualUserData.source_of_fund_code
                  ? IndividualUserData.source_of_fund_code.value
                  : ""
              }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      IndividualUserData: {},
    };
  },
  created() {
    this.getIndividualData();
  },
  methods: {
    async getIndividualData() {
      const responseData = await this.$store.dispatch(
        "Authentication/getProfile"
      );
      this.IndividualUserData = responseData.data.data[0];
      console.log("getIndividualData", responseData.data.data[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
</style>
